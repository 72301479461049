import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import Divider from "../layout/Divider";

const SimpleTextItem = ({ text, dividerSize, style }) => {
  return (
    <div>
      <div className="row" style={{ padding: "0px", margin: "0px" }}>
        <div className="col-12">
          <h5 style={ style || { fontFamily: "farid" }}>{text}</h5>
        </div>
      </div>
      <Divider size={dividerSize || "none"}></Divider>
    </div>
  );
};

export default SimpleTextItem;
