import React from 'react';
import Images from './Images';

const Intro = props => {
	const info = props.info;

	return (
		<div>
			<Images images={info.images}></Images>

		</div>
	);
};

export default Intro;
