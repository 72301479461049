import React, { Fragment } from 'react';
import Divider from '../layout/Divider';
import CVItem from '../general/CVItem';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Experience = (props) => {
	const experiences = props.experiences;

	return (
		<div className='row experience' >
			<div className='col-12 title' style={{ margin: '0px' }}>
			<FontAwesomeIcon icon={faSuitcase}/> Berufliche Erfahrung
			</div>
			<div className='description' style={{ paddingTop: '0px' }}>
			<Divider size='medium'></Divider>

			{experiences.map((experience) => (
				<Fragment>
					<CVItem cvEntry={experience}/>
				</Fragment>
			))}
		</div>
		</div>

	);
};

export default Experience;
