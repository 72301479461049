import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import Divider from "./layout/Divider";
import About from "./sections/About";
import styled from 'styled-components';
import Skills from './sections/Skills';
import Experiences from './sections/Experiences';
import Projects from './sections/Projects';
import Educations from './sections/Educations';
import Contact from './sections/Contact';
import Impressum from './sections/Impressum';
import Intro from './sections/Intro';

const HomePage = (props) => {
  const {
    info,
    skills,
    educations,
    experiences,
    projects,
    contact,
    settings,
  } = props.data;

  const Container = styled.div`
    height: 100vh;
    overflow-y: scroll;
    background-color: ${settings.backgroundColor};
    position: relative;
    color: ${settings.textColor};
  `;
  return (
      <Container className="col-lg-12 col-sm-12 info-div">
        <div className="row">
          <div className="col-lg-6 col-xs-12">
            <div id="leftDiv">
              <Intro info={info} />
            </div>
          </div>
          
          			    
          <div className="col-lg-6 col-xs-12">
            <Divider size="large" />

            <About about={info.about} />
            <Divider size="large" />
            <Experiences experiences={experiences} />
            <Divider size="large" />
            <Educations educations={educations} />
            <Divider size="large" />
            <Skills skills={skills} />
            <Divider size="large" />
            <Projects projects={projects} />
            <Divider size="large" />
            <Contact contact={contact}/>
            <Divider size="large" />
            <Impressum />            
           </div>
        </div>
      </Container>
  );
};

export default HomePage;
