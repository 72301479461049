import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import Divider from "../layout/Divider";
import SimpleTextItem from "./SimpleTextItem";

const Nestedtem = ({ item, dividerSize }) => {
  return (
    <div>
      <div className="row" style={{ padding: "0px", margin: "0px" }}>
        <div className="col-12">
          <h5 style={{ fontFamily: "farid" }}>{item.header.name}</h5>
        </div>
      </div>
      {item.skills.map((skill) => (
        <div
          className="offset-1">
          <SimpleTextItem text={skill.name} />
        </div>
      ))}
      <Divider size={dividerSize || "medium"}></Divider>
    </div>
  );
};

export default Nestedtem;
