import React, { Fragment } from "react";
import CVItem from "../general/CVItem";
import Divider from "../layout/Divider";
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Projects = (props) => {
  const projects = props.projects;

  return (
    <div className="row">
      <div className="col-12 title" style={{ margin: "0px" }}>
        <FontAwesomeIcon icon={faProjectDiagram} /> Projekte & Engagements
      </div>
      <div className="description" style={{ paddingTop: "0px" }}>
        <Divider size="medium"></Divider>
        {projects.map((project, index) => (
          <Fragment>
            <CVItem cvEntry={project} />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Projects;
