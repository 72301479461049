import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import Divider from "../layout/Divider";

const CVItem = ({ cvEntry, dividerSize }) => {
  return (
    <div>
      <div className="row" style={{ padding: "0px", margin: "0px" }}>
        <div className="col-lg-8 col-sm-12">
          <h5 style={{ fontFamily: "farid" }}> 
          {(cvEntry.link ? (<a href={cvEntry.link}> {cvEntry.title} </a>) : (cvEntry.title))}
          </h5>
        </div>
        <div className="col-lg-3 col-sm-12">
          <h6 style={{ fontFamily: "farid" }}>{cvEntry.time}</h6>
        </div>
        <div
          className="col-lg-11 offset-lg-1 col-sm-12"
          style={{ textAlign: "justify", fontSize: "0.8rem" }}
        >
          <h6>
            {cvEntry.location} {cvEntry.location && cvEntry.description && "//"}{" "}
            {cvEntry.description}
          </h6>
        </div>
      </div>
      <Divider size={ dividerSize || "medium"}></Divider>
    </div>
  );
};

export default CVItem;
