import React, { Fragment } from "react";
import $ from "jquery";
import "../../js/jquery.toast.js";

const Images = (props) => {
  let clicked = 0;
  let clickRate = 0;
  let firstTime = new Date();
  let debugModeSeconds = 3;
  let debugModeClick = 5;

  const clickOnMyPic = () => {
    clickRate = (new Date().getTime() - firstTime.getTime()) / 1000;
    clicked++;
    if (clicked % debugModeClick === 0) {
      if (clickRate < debugModeSeconds)
        $.toast({
          heading: "Eyyyyyyy !",
          text: "Hey hör auf damit! &#128529; ",
          showHideTransition: "slide",
        });
      else firstTime = new Date();
    }
  };

  return (
    <Fragment>
      <img
        src={process.env.PUBLIC_URL + "/" + props.images[0].background_image}
        className="profile-image"
        alt="Leonie Janowicz"
        onClick={clickOnMyPic}
      ></img>

      
    </Fragment>
  );
};

export default Images;
