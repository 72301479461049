import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import Divider from '../layout/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Contact = (props) => {
    const contact = props.contact;
    return (
        <Fragment>
            <div className='Contact'>
                <div className='title'><FontAwesomeIcon icon={faAddressBook} /> Contact</div>
                <Divider size='medium'></Divider>

                <div>
                    <div className='description' style={{ fontFamily: 'farid', marginTop: '20px' }}>
                        {contact.email}
                        <br></br>
                        <a className='link' href={'http://linkedin.com/' + contact.linkedin}>
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Contact;
