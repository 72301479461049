import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faParagraph } from '@fortawesome/free-solid-svg-icons';

const Impressum = (props) => {
    return (
        <Fragment>
            <div className='Impressum'>
                <div className='title'><FontAwesomeIcon icon={faParagraph} /><a href="https://philipp.desomb.re/impressum"> Impressum </a> </div>
            </div>
        </Fragment>
    );
};

export default Impressum;
