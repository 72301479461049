import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './css/jquery.toast.css';
import Homepage from './components/Homepage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import SimpleReactLightbox from 'simple-react-lightbox';

library.add(fab, faCheckSquare, faCoffee);

function App() {
	const data = require('./config.json');
	return (
		<SimpleReactLightbox>
				<div style={{ 'backgroundColor' : data.settings.backgroundColor}}>
				<div className='container-fluid'>
					<div className='row'>
						 <Homepage data={data} />
					</div>
				</div>
				</div>
		</SimpleReactLightbox>
	);
}

export default App;
