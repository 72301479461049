import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import Divider from '../layout/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';

const About = (props) => {
	const about = props.about;
	return (
		<Fragment>
				<div className='about'>
					<div className='title'><FontAwesomeIcon icon={faComment}/> About Me</div>
					<Divider size='medium'></Divider>

					<div className='description' dangerouslySetInnerHTML={{__html: about}}></div>
				</div>
		</Fragment>
	);
};

export default About;
