import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nestedtem from '../general/NestedItem.';
import Divider from '../layout/Divider';

const Skills = (props) => {
	const skills = props.skills;

	return (
		<div className='row'>
			<div className='title col-12'><FontAwesomeIcon icon={faChalkboardTeacher}/> Fortbildungen & Fähigkeiten</div>
			<div className="description" style={{ paddingTop: "0px" }}>
        <Divider size="medium"></Divider>
			{skills.map((skill) => (
				<Nestedtem item={skill}/>
			))}
		</div>
		</div>
	);
};

export default Skills;
