import React, { Fragment } from 'react';
import Divider from '../layout/Divider';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import CVItem from '../general/CVItem';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Education = (props) => {
	const educations = props.educations;
	return (
		<div className='row experience'>
			<div className='col-12 title'><FontAwesomeIcon icon={faGraduationCap}/> Bildung</div>
			<div className='description' style={{ paddingTop: '0px' }}>
			<Divider size='medium'></Divider>
				{educations.map((education) => (
					<Fragment>
						<CVItem cvEntry={education} />
					</Fragment>
				))}
			</div>
		</div>
	);
};

export default Education;
